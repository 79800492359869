import React from 'react';
import './layout.scss';

const bodyBox = ({ children }) => {
  return (
    <div className="body-box">
      {children}
    </div>
  );
};

export default bodyBox;
