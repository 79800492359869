import "./coachMatching.scss";

import BodyBox from "../../layouts/bodyBox";
import { Button } from "react-bootstrap";
import React from "react";
import SEO from "../../components/Seo";

const Results = () => {
  return (
    <BodyBox>
      <SEO title="Coach Results" />

      <div className="account shadow p-3 mb-5 bg-white rounded ">
        <div className="d-flex justify-content-center">
          <h1 className="text-success">Welcome Coach</h1>
        </div>
        <hr />

        <div className="d-flex justify-content-center">
          <h3 className="text-info">Next Steps:</h3>
        </div>
        <div className="d-flex justify-content-center">
          <p className="text-center">
            Sign In or Sign Up to your Walletgyde account:
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <Button variant="primary">Sign In</Button>
          <span className="inline-text text-info">or</span>
          <Button variant="secondary">Sign Up</Button>
        </div>
      </div>
    </BodyBox>
  );
};

export default Results;
